import { graphql } from 'gatsby'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { GridThemeProvider, BaseCSS } from 'styled-bootstrap-grid'
import styled, { ThemeProvider } from 'styled-components'

import {
  ResetCSS,
  GlobalCSS,
  Card,
  Container,
  GRID_UNIT,
  Layout as UILayout,
  LinkButton,
  Markdown,
  Text,
  fromSize,
} from 'mf-ui-components'

import gridTheme from '../utils/gridTheme'
import lang, * as l10n from '../utils/l10n'
import Layout from '../components/Layout'
import { SEO } from '../components/SEO'
import { getTheme } from '../utils/style/getTheme'
import { spacing } from '../utils/ui/spacing'
import { i18n } from '../utils/i18n'

const StyledCard = styled(Card)`
  margin-bottom: 40px;
  box-shadow: none;
  text-align: center;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${spacing('lg')};
  gap: ${spacing('xs')};

  ${fromSize('md')} {
    flex-direction: row;
  }
`

const WrapperImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  margin-bottom: ${spacing('xl')};
  animation: bounceInUp 1s both;

  @keyframes bounceInUp {
    0% {
      opacity: 0;
      transform: translateY(48px) rotate(8deg);
    }

    60% {
      transform: translateY(-16px) rotate(-4deg);
    }

    80% {
      transform: translateY(8px) rotate(2deg);
    }

    100% {
      opacity: 1;
      transform: translateY(0) rotate(0deg);
    }
  }
`

const Illustration = ({ theme }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 125.4 152.5"
    style={{ height: '100%' }}
    fill={theme.tokens.accent.token.primary.regular}
    aria-hidden
  >
    <path d="m85.9,85.1l-5.5,3.9-4.4-6.2c18.9-15.3,23-42.9,8.8-63.1C70-1.3,40.8-6.3,19.8,8.5,9.6,15.7,2.8,26.4.7,38.7c-2.1,12.3.7,24.7,7.8,34.8s17.9,17,30.2,19.1c2.7.5,5.4.7,8,.7,8.5,0,16.8-2.3,24.2-6.8l4.4,6.2-5.5,3.9,39.4,56,16.2-11.4-39.4-56Zm-46.2,1.3c-10.6-1.8-19.9-7.7-26.1-16.5-6.2-8.8-8.6-19.5-6.8-30.2,1.8-10.6,7.7-19.9,16.5-26.1,7.1-5,15.2-7.4,23.2-7.4,12.7,0,25.2,6,33.1,17.1,6.2,8.8,8.6,19.5,6.8,30.2-1.8,10.6-7.7,19.9-16.5,26.1-8.8,6.2-19.5,8.6-30.2,6.8Zm38.7,11.6l6-4.2,32.2,45.7-6,4.2-32.2-45.7Z" />
    <path d="m36.4,24.5l2.3,5.1c2.5-1.2,10.3-3.8,13.1-.4,2,2.4,2.4,4.7-1.4,9.3-.6.7-1.2,1.3-1.8,1.9-1.7,1.7-3.7,3.6-4.8,6.8-.9,2.5-1.3,5.9-1.2,10.3h6.3c0-4.9.5-7.2.9-8.2.7-1.8,1.8-3,3.3-4.4.8-.8,1.6-1.5,2.3-2.4,1.9-2.3,7.8-9.4,1.4-17.2-6.5-8-19.7-1-20.3-.7Z" />
    <path d="m45.8,63.5c-2.4,0-4.3,1.9-4.3,4.3s1.9,4.3,4.3,4.3,4.3-1.9,4.3-4.3-1.9-4.3-4.3-4.3Z" />
  </svg>
)

const FourOhFour = ({
  data: {
    wpgraphql: {
      globalOptions: {
        generalOptions: { theme },
      },
    },
  },
}) => {
  const styledTheme = getTheme(theme)
  
  return (
  <GridThemeProvider gridTheme={gridTheme}>
    <ThemeProvider theme={styledTheme}>
      <IntlProvider messages={i18n()} locale={process.env.GATSBY_LANG}>
        <ResetCSS />
        <GlobalCSS />
        <BaseCSS />
        <Layout themeId={theme}>
          <SEO title={`${l10n.error404.documentTitle[lang]} | Moneyfarm`} />
          <Container>
            <UILayout width="10" mode="column" paddingTop={GRID_UNIT * 5}>
              <StyledCard padding={GRID_UNIT * 4}>
                <WrapperImage>
                  <Illustration theme={styledTheme} />
                </WrapperImage>
                  <Text el="h5" styleName="title5" style={{marginBottom:styledTheme.tokens.spacing.xs}}>
                    {l10n.error404.bodyTitle[lang]}
                  </Text>
                <Markdown markdown={l10n.error404.bodyMessage[lang]} />
                <StyledDiv>
                  <LinkButton
                    href={`${process.env.GATSBY_SITE_URL}${process.env.GATSBY_PATH_PREFIX}${l10n.error404.secondaryCTAURL[lang]}`}
                    role="secondary"
                    size="normal"
                  >
                    {l10n.error404.secondaryCTA[lang]}
                  </LinkButton>
                  <LinkButton
                    href={`${process.env.GATSBY_SITE_URL}${process.env.GATSBY_PATH_PREFIX}/`}
                    role="primary"
                    size="normal"
                  >
                    {l10n.error404.primaryCTA[lang]}
                  </LinkButton>
                </StyledDiv>
              </StyledCard>
            </UILayout>
          </Container>
        </Layout>
      </IntlProvider>
    </ThemeProvider>
  </GridThemeProvider>
)}

export default FourOhFour

export const pageQuery = graphql`
  query GET_DATA {
    wpgraphql {
      globalOptions {
        generalOptions {
          theme
        }
      }
    }
  }
`
